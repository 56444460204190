import { theme, useResponsive } from "@product/scmp-sdk";
import chunk from "lodash/chunk";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { tracking } from "scmp-app/data";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { openQuestionsSeriesDiscoveryModuleQuery$key } from "scmp-app/queries/__generated__/openQuestionsSeriesDiscoveryModuleQuery.graphql";

import {
  Container,
  MoreLink,
  RestItems,
  StyledContentItemHomeSecondary,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperContainer,
  Title,
  TopItems,
} from "./styles";

type Props = {
  className?: string;
  reference: openQuestionsSeriesDiscoveryModuleQuery$key;
};

export const OpenQuestionsSeriesDiscoveryModule: FunctionComponent<Props> = ({
  className,
  reference,
}) => {
  const data = useFragment(
    graphql`
      fragment openQuestionsSeriesDiscoveryModuleQuery on Query
      @argumentDefinitions(scmpPlusPaywallTypeIds: { type: "[String]", defaultValue: [] }) {
        openQuestionSeriesTopic: topic(filter: { entityId: "518656" }) {
          name
          urlAlias
          contents(
            exclude: { paywallTypeIds: $scmpPlusPaywallTypeIds }
            first: 6
            orderBy: { field: PUBLISHED_DATE, direction: DESC }
          ) {
            edges {
              node {
                ... on Article {
                  entityId
                  ...homeSecondaryContentItemContent
                }
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const topicName = data?.openQuestionSeriesTopic?.name;
  const topicUrlAlias = data?.openQuestionSeriesTopic?.urlAlias;
  const Items = data?.openQuestionSeriesTopic?.contents?.edges ?? [];
  const isTabletUp = useResponsive(theme.breakpoints.up("tablet"), false);
  const currentPageType = useCurrentPageType();

  if (Items?.length === 0) return null;

  const items = isTabletUp ? Items.slice(0, 5) : Items;
  const chunkItems = chunk(items, 2);
  const topItems = items.slice(0, 2);
  const restItems = items.slice(2);

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.OpenQuestionsSeries,
        pgtype: currentPageType,
      }}
    >
      <Container className={className}>
        <Title>{topicName}</Title>

        <TopItems>
          {topItems.map(({ node }) => (
            <StyledContentItemHomeSecondary
              hideHeadlineTag={true}
              key={node.entityId}
              reference={node}
              withComment
              withImage
              withSummary={false}
            />
          ))}
        </TopItems>
        <RestItems>
          {restItems.map(({ node }) => (
            <StyledContentItemHomeSecondary
              hideHeadlineTag={true}
              key={node.entityId}
              reference={node}
              withComment
              withImage
              withSummary={false}
            />
          ))}
        </RestItems>

        <SwiperContainer>
          <StyledSwiper
            breakpoints={{
              768: {
                direction: "vertical",
                slidesPerView: 5,
                spaceBetween: 16,
              },
            }}
            cssMode={false}
            navigation={false}
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
            spaceBetween={16}
          >
            {chunkItems.map((item, index) => (
              <StyledSwiperSlide key={index}>
                {item.map(({ node }) => (
                  <StyledContentItemHomeSecondary
                    hideHeadlineTag={true}
                    key={node.entityId}
                    reference={node}
                    withComment
                    withImage
                  />
                ))}
              </StyledSwiperSlide>
            ))}
          </StyledSwiper>
        </SwiperContainer>
        <MoreLink pathname={topicUrlAlias}>MORE FROM THIS SERIES </MoreLink>
      </Container>
    </BaseLinkContextProvider>
  );
};

OpenQuestionsSeriesDiscoveryModule.displayName = "OpenQuestionsSeriesDiscoveryModule";
